import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from '../components/layout';
import SectionTitle from '../components/section-title';
import { CurrentUserAsync } from '../services/parse-bridge';
import LoadingIndicator from '../components/loading-indicator';

class ProfilePage extends Component {
    state = {
        user: null
    }

    componentDidMount() {
        CurrentUserAsync().then((user) => {
            this.setState({ user })
        })
    }


    render() {
        const { user } = this.state

        if (!user) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
                    <LoadingIndicator />
                </div>
            )
        }

        return (
            <Layout>
                <Container>
                    <SectionTitle
                        title="Your Profile"
                        subtitle="Manage your YUP account"
                        margin
                    />
                    <ProfileContainer>
                        <PictureContainer>
                            <ProfilePic src={user.profilePicture} />

                        </PictureContainer>
                        <InfoContainer>
                            <Heading>User Information</Heading>
                            <Name>{user.fullName}</Name>
                            <Email>{user.email}</Email>
                            <Gender>{user.gender}</Gender>
                        </InfoContainer>
                    </ProfileContainer>
                </Container>
            </Layout>
        );
    }
}
export default ProfilePage;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

`
const ProfileContainer = styled.div`
    max-width: 1000px;
    margin-top: 50px;
    border: 1px solid #595959;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: 450px) {
        flex-direction: column;

    }
`
const PictureContainer = styled.div`
  
`;
const ProfilePic = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 3px;
`
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Lato";
    margin-left: 20px;

    @media (max-width: 450px) {
        margin: 0;

    }

`
const Heading = styled.h1`
      font-family: 'bebasbold';
    font-size: 18px;
    margin: 0 0 7px 0;
`;
const Name = styled.h2`
    font-size: 18px;
font-weight: 400;
`
const Email = styled.h3`
    font-size: 16px;
    margin: 0 0 5px 0;
    font-weight: 400;

`
const Gender = styled.h3`
    margin: 0 0 5px 0;
    font-size: 16px;

`