import React from 'react';
import styled from 'styled-components'
import Loader from 'react-loader-spinner';

const CenterBlock = styled.div`
	text-align: center;
	display: block;
	margin: 0 auto;
`;


const LoadingIndicator = props => (
    <CenterBlock>
        <Loader
            type="ThreeDots"
            color="#B4271D"
            height="50"
            width="50"
        />
    </CenterBlock>
)

export default LoadingIndicator